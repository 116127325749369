import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

import { clock, renderer, scenes, textures, mainCamera } from './index.js';
import { animatePlanets } from './planets.js';
import { fragmentShaderItemBackground, vertexShader } from './shaders.js';

export const watches = [
    {
        name: 'Earth',
        img: `/assets/img/watches/earth.png`,
        content: [
            'A singular oasis in the unforgivable expanse of space, Earth is our home.',
            'Looking back on it from above, borders, politics, and people groups fade away and we see our planet clearly as a living and complex organism.',
            'From its deserts to its forests, Earth is bursting with life.',
            'The Astronomia Metaverso Earth is a beautiful reminder of this precious treasure we inhabit, and a reminder to care for the life we all share here.'
        ]
    },
    {
        name: 'Jupiter',
        img: `/assets/img/watches/jupiter.png`,
        content: [
            'Jupiter is the king of the planets.',
            'Its complex clouds whip across the tumultuous surface in contrasting bands of fiery red, orange, and brilliant white.',
            'Fantastic displays of powerful storms are continually brewing, with the most famous of all being Jupiter’s Great Red Spot.',
            'Jupiter is also known as the great-shepherd planet, capturing and herding thousands of asteroids with its powerful gravitational pull, preventing them from coming close to Earth.',
            'All hail the king!'
        ]
    },
    {
        name: 'Mars',
        img: `/assets/img/watches/mars.png`,
        content: [
            'Mars is the future.',
            'Just as fervor swept across the world to place a man on the moon in the 1960’s, so now our ambitions have turned towards the red planet, Mars.',
            'A planet now solely inhabited by our rovers and satellites, Mars may soon play host to humans for the first time in history.',
            'Mars burns bright with potential, a rewarding challenge worthy of pursuit.'
        ]
    },
    {
        name: 'Mercury',
        img: `/assets/img/watches/mercury.png`,
        content: [
            'Mercury shows its splendor through its scars.',
            'Hundreds of timeworn craters sculpt the surface in a manner very similar to our own moon with the newest meteoric impacts still displaying their striking starburst patterns.',
            'In its position closest to our sun, Mercury lives in a dual state of both fire and ice. The sunlit side can reach scorching temperatures of almost 800°F while the shadow side can dip down to a frigid -300°F.',
            'Though the smallest of the eight, Mercury sends a dazzling message of determination in the face of fire.'
        ]
    },
    {
        name: 'Neptune',
        img: `/assets/img/watches/neptune.png`,
        content: [
            'Like a deep blue ocean, Neptune flows freely furthest from the sun.',
            'Its sapphire-colored clouds streak through the Neptunian atmosphere with winds reaching an astonishing 1500 miles per hour.',
            'Light from our sun takes over 4 hours to arrive making Neptune’s domain both dark and cold.',
            'Although it is astronomically distant and unable to be seen with the eye, Neptune’s presence was predicted with mathematics in 1846.',
            'Sometimes even if you can’t see the something, you can still feel its power.'
        ]
    },
    {
        name: 'Saturn',
        img: `/assets/img/watches/saturn.png`,
        content: [
            'Saturn is the god of time.',
            'It has dazzled generations of people with its brilliant icy rings, ever since Galileo first discovered them in 1610.',
            'Showing off in style, a white whirlwind storm develops in the northern half of the planet during Saturn’s spring when it tilts toward the sun.',
            'Its pale gold atmosphere is alive with many different subtly detailed bands.',
            'Pressing ever forward, the keeper of time moves relentlessly onward into the future.'
        ]
    },
    {
        name: 'Uranus',
        img: `/assets/img/watches/uranus.png`,
        content: [
            'A world of icy blue, Uranus is simply unique.',
            'Rotating on its side relative to the other planets, this ice giant rolls to the beat of a different drum',
            'The frigid clouds reflect a pale-blue color appropriate for the Greek god of the sky.',
            'Though less extensive than Saturn’s rings, Uranus displays thinner but brilliant rings that stand out due to the planet\'s special sideways orientation.',
            'Sometimes it is best to just be yourself, no matter what others are doing.'
        ]
    },
    {
        name: 'Venus',
        img: `/assets/img/watches/venus.png`,
        content: [
            'A true femme fatale, Venus is as beautiful as she is deadly.',
            'Distinctive lightning-shaped patterns ripple across her golden surface which is shrouded in a crushing atmosphere.',
            'This goddess of a planet has won the Hottest-in-the-Solar-System crown many years running with temperatures reaching a blistering 900°F.',
            'She’s beauty and she’s grace, but she will also melt your face.'
        ]
    }
];

const geometryParticles = new THREE.InstancedBufferGeometry();

export const watchState = {
    position: { x: 0, y: 0, z: 820 },
    rotation: { x: 0, y: 0, z: 0 }
}

export function setWatchState(p, r) {
    watchState.position = p;
    watchState.rotation = r;
}

export let rotateWatchParticlesInterval;
export let rotateWatchBackgroundParticlesInterval;

export function initWatchParticles(scene, planetInfo) {
    const foundTexture = textures.find(texture => texture.name === `${planetInfo.name}-watch-particles`);
    if (!foundTexture) {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(`${process.env.PUBLIC_URL}${planetInfo.img_background}`, (watchBackgroundTexture) => {

            watchBackgroundTexture.name = `${planetInfo.name}-watch-particles`;
            textures.push(watchBackgroundTexture);

            const watchBackgroundMesh = watchBackgroundPixelExtraction(watchBackgroundTexture);
            scene.add(watchBackgroundMesh);


            registerEvents();
            rotateWatchParticles(scene);

        });
    } else {

        const watchBackgroundMesh = watchBackgroundPixelExtraction(foundTexture);
        scene.add(watchBackgroundMesh);

        registerEvents();
        rotateWatchParticles(scene);

    }
}

function watchBackgroundPixelExtraction(watchBackgroundTexture) {

    const width = watchBackgroundTexture.source.data.width * 1.5;
    const height = watchBackgroundTexture.image.height * 1.5;
    const totalPoints = width * height;

    const img = watchBackgroundTexture.image;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    ctx.scale(1, -1);
    ctx.drawImage(img, 0, 0, width, height * -1);

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const arrayOfColors = Float32Array.from(imgData.data);

    return initWatchParticlesBackground(width, height, totalPoints, arrayOfColors, watchBackgroundTexture);

}

function initWatchParticlesBackground(width, height, totalPoints, arrayOfColors, texture) {

    const positions = new THREE.BufferAttribute(new Float32Array(4 * 3), 3);
    positions.setXYZ(0, -0.5, 0.5, 0.0);
    positions.setXYZ(1, 0.5, 0.5, 0.0);
    positions.setXYZ(2, -0.5, -0.5, 0.0);
    positions.setXYZ(3, 0.5, -0.5, 0.0);
    geometryParticles.setAttribute('position', positions);

    const uvs = new THREE.BufferAttribute(new Float32Array(4 * 2), 2);
    uvs.setXYZ(0, 0.0, 0.0);
    uvs.setXYZ(1, 1.0, 0.0);
    uvs.setXYZ(2, 0.0, 1.0);
    uvs.setXYZ(3, 1.0, 1.0);

    geometryParticles.setAttribute('uv', uvs);
    geometryParticles.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1));

    const offsets = new Float32Array(totalPoints * 3);
    const indices = new Uint16Array(totalPoints);
    const angles = new Float32Array(totalPoints);

    for (let i = 0, j = 0; i < totalPoints; i++) {
        if (arrayOfColors[i * 4 + 0] <= 30) continue;
        offsets[j * 3 + 0] = i % width;
        offsets[j * 3 + 1] = Math.floor(i / width);
        indices[j] = i;
        angles[j] = Math.random() * Math.PI;
        j++;
    };

    geometryParticles.setAttribute('offset', new THREE.InstancedBufferAttribute(offsets, 3, false));
    geometryParticles.setAttribute('angle', new THREE.InstancedBufferAttribute(angles, 1, false));
    geometryParticles.setAttribute('pindex', new THREE.InstancedBufferAttribute(indices, 1, false));

    const uniforms = {
        uTime: { value: 0 },
        uRandom: { value: 50 },
        uDepth: { value: 25.0 },
        uSize: { value: 0.6 },
        uTextureSize: { value: new THREE.Vector2(width, height) },
        uTexture: { value: texture },
        uTouch: { value: null },
        uAlphaCircle: { value: 0 },
        uAlphaSquare: { value: 0.0 },
        uCircleORsquare: { value: 0.0 },
    };

    const materialParticles = new THREE.RawShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader(),
        fragmentShader: fragmentShaderItemBackground(),
        depthTest: false,
        transparent: true,
    });

    const particles = new THREE.Mesh(geometryParticles, materialParticles);
    particles.name = 'watch-particles-mesh';
    particles.needsUpdate = true;
    particles.renderOrder = 0;

    return particles;
}

export function rotateWatchParticles(scene) {

    let watchMeshMaterial = scene.children.find(material => material.name === 'watch-particles-mesh');

    if (watchMeshMaterial) {

        if (rotateWatchParticlesInterval) clearInterval(rotateWatchParticlesInterval);
        rotateWatchParticlesInterval = setInterval(() => {
            try {

                watchMeshMaterial.rotation.z += 0.00075;

                const test = clock.getDelta();
                watchMeshMaterial.material.uniforms.uTime.value += test;

            } catch (error) {
                console.log('Ops! U were too fast!');
            }
        }, 40);
    } else {
        setTimeout(() => {
            rotateWatchParticles(scenes[1]);
        }, 20);
    }
}

export function goBackToExplore() {
    scenes[1].visible = false;
    if (window.innerWidth < 960) {
        new TWEEN.Tween(mainCamera).to({ position: { x: 0, y: 0, z: 930 } }, 1500).easing(TWEEN.Easing.Cubic.Out).start();
    } else {
        new TWEEN.Tween(mainCamera).to({ position: { x: 0, y: 0, z: 900 } }, 1500).easing(TWEEN.Easing.Cubic.Out).start();
    }
    animatePlanets(scenes[0], { opacity: 1 });
}

export function loadBackGroundWatchParticles(scene) {

    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    const textureLoader = new THREE.TextureLoader();
    const sprites = textureLoader.load(`${process.env.PUBLIC_URL}/assets/img/textures/ball.png`);

    for (let i = 0; i < 4500; i++) {

        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 - 1000;
        const z = Math.random() * 2000 - 1000;

        vertices.push(x, y, z);

    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    const materials = [];
    const parameters = [
        [[0.65, 0.54, 0.32], sprites, 9],
        [[0.75, 0.34, 0.32], sprites, 9],
        [[0.55, 0.34, 0.52], sprites, 7],
    ];

    for (let i = 0; i < parameters.length; i++) {

        const color = parameters[i][0];
        const sprite = parameters[i][1];
        const size = parameters[i][2];

        materials.push(new THREE.PointsMaterial({
            size: size,
            map: sprite,
            blending: THREE.AdditiveBlending,
            transparent: true,
            depthTest: false,
            opacity: 0.4
        }));

        materials[i].color.setHSL(color[0], color[1], color[2]);

        const particles = new THREE.Points(geometry, materials[i]);
        particles.needsUpdate = true;
        particles.name = 'sprites-mesh';
        particles.position.z = 0;

        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

        scene.add(particles);

    }
}

const registerEvents = () => {

    let watchMeshMaterial = scenes[1].children.find(material => material.name === 'watch-particles-mesh');

    const planetContainerElement = document.getElementById('jcob-container-elem');
    planetContainerElement?.addEventListener('pointermove', (event) => onWatchPointerMove(event, watchMeshMaterial));
}

export function onWatchPointerMove(event, material) {
    try {

        let x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
        let y = - (event.clientY / renderer.domElement.clientHeight) * 2 + 1;

        material.rotation.y = (x / 16);
        material.rotation.x = - (-y / 12);

    } catch (error) {
        console.log('Ops! U were too fast!');
    }
}

export const hideNonNeededElements = () => {
    scenes[0].children[5].visible = false;
    scenes[0].children[6].visible = false;
    scenes[0].children[7].visible = false;
}

export const showNonNeededElements = () => {
    scenes[0].children[5].visible = true;
    scenes[0].children[6].visible = true;
    scenes[0].children[7].visible = true;
}

const preloadImage = async (url) => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.addEventListener('load', () => {
            resolve(image);
        });
        image.addEventListener('error', (err) => reject(err));
    });
}

export function preloadImages() {
    imagesRoutes.forEach(image => {
        preloadImage(image);
    });
}

let imagesRoutes = [
    "/assets/img/watches/earth.png",
    "/assets/img/watches/jupiter.png",
    "/assets/img/watches/mars.png",
    "/assets/img/watches/mercury.png",
    "/assets/img/watches/neptune.png",
    "/assets/img/watches/saturn.png",
    "/assets/img/watches/uranus.png",
    "/assets/img/watches/venus.png"
];

export default watches;