import React, { useState } from 'react';

import './styles.scss';

function WatchLoader({ imgUrl }: { imgUrl: string }) {

    const [imgLoaderState, setImgLoaderState] = useState(true);

    const image = new Image();
    image.addEventListener('load', () => {
        setImgLoaderState(false);
    });
    image.src = imgUrl;

    if (imgLoaderState) {
        return (
            <div className="watch-loader-container">
                <div className="watch-loader-logo">
                    <div className="watch-loader-circle"></div>
                </div>
            </div>
        );
    }

    return (
        <img src={image.src} />
    );

}

export default WatchLoader;