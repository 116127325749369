import React from 'react';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';

// UTILS
import { playAudio } from '../utils';

function ExploreAllButton({ arrowDirection, buttonText, action }: { arrowDirection: string, buttonText: string, action: any }) {

    const [state, dispatch] = useGlobalState();

    return (
        <div className={`explore-button-container ${state.isNavigation ? 'active' : ''}`} onClick={() => action()}>
            <button className="explore-button" onMouseEnter={() => state.isNavigation && state.audio && playAudio()}>
                {arrowDirection === 'left' ?
                    <div className="center-con">
                        <div className={`round ${arrowDirection}`}>
                            <div id="cta">
                                <span className={`arrow primera next ${arrowDirection}`}></span>
                            </div>
                        </div>
                    </div> : null
                }
                {buttonText}
                {arrowDirection === 'right' ?
                    <div className="center-con">
                        <div className={`round ${arrowDirection}`}>
                            <div id="cta">
                                <span className={`arrow primera next ${arrowDirection}`}></span>
                            </div>
                        </div>
                    </div> : null
                }
            </button>
        </div>
    );
};

export default ExploreAllButton;