import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

// THREE
import { mainCamera, scenes } from '../Three/index';
import { addPlanet, animatePlanets, planets, planetState } from '../Three/planets';
import { watches, initWatchParticles, goBackToExplore, hideNonNeededElements, showNonNeededElements } from '../Three/watches';

// TWEEN
import * as TWEEN from '@tweenjs/tween.js';

// STATE
import { useGlobalState } from '../index';

// COMPONENTS
import AboutDropButton from '../shared/AboutDropButton';
import Loader from '../Loader';
import Navigator from './Navigator'
import WatchLoader from '../shared/WatchLoader';

// STYLES
import './styles.scss';

// UTILS
import { playAudio } from '../shared/utils';
import { animateScene } from '../Three/scene';
import { galaxyState } from '../Three/galaxy';

function ViewAll() {

    const [state, dispatch] = useGlobalState();
    const navigate = useNavigate();

    const goBack = () => {
        showNonNeededElements();
        goBackToExplore();
        navigate(`/explore`);
    }

    return (
        <div className="view-all-container">
            <button className="view-all-button" onClick={() => goBack()} onMouseEnter={() => state.audio && playAudio()}>
                <div className="center-con">
                    <div className="round">
                        <div id="cta">
                            <span className="arrow primera next "></span>
                        </div>
                    </div>
                </div>
                View All
            </button>
        </div>
    );
};

function Watch() {

    const navigate = useNavigate();

    const { watchName } = useParams<{ watchName: string }>();

    const [state, dispatch] = useGlobalState();
    const [watchInfoState, setWatchInfoState] = useState<any>();
    const [planetInfoState, setPlanetInfoState] = useState<any>();
    const [visibaleState, setVisibleState] = useState<boolean>(true);
    const [fadeInState, setFadeInState] = useState<boolean>(false);

    const getWatchInfo = (watchName: string): void => {

        const watchInfo = watches.find(watch => watch.name === watchName);
        const planetInfo = planets.find(planet => planet.name === watchName);

        setWatchInfoState(watchInfo);
        setPlanetInfoState(planetInfo);
        setFadeInState(true);

    };

    const getWatchContent = (watchInfo: any): JSX.Element => {
        return watchInfo.content.map((paragraph: string, i: number) => {
            return (<p key={i}>{paragraph}</p>);
        });
    };

    const removeMouseDownEvent = () => {
        const jcobElement = document.getElementById('jcob-container-elem');
        jcobElement?.removeEventListener('mousedown', state.onMouseDownRef);
    };

    const initThree = () => {

        if (window.innerWidth < 960) {
            scenes[0].position.set(0, 0, 777);
        } else {
            scenes[0].position.set(0, 0, 807);
        }
        scenes[0].rotation.set(-0.7, 0, 0);

        dispatch({ isNavigation: true });

        mainCamera.position.set(planetInfoState.position.x, planetInfoState.position.y, 810);

        scenes[1].position.set(planetState.initialPosition.x, planetState.initialPosition.y, planetState.initialPosition.z);
        scenes[1].visible = true;

        setMainSceneState();

        new TWEEN.Tween(scenes[1]).to({ position: planetState.activePosition }, 1500).easing(TWEEN.Easing.Cubic.Out).start();

    };

    const setMainSceneState = () => {
        if (
            scenes[0].children[5] &&
            scenes[0].children[6] &&
            scenes[0].children[7]
        ) {
            hideNonNeededElements();
        } else {
            setTimeout(() => {
                setMainSceneState();
            }, 20);
        }
    };

    const aboutTheDropWatchAction = () => {

        scenes[1].visible = false;

        setVisibleState(false);
        showNonNeededElements();

        if (window.innerWidth < 960) {
            new TWEEN.Tween(mainCamera).to({ position: { x: 0, y: 0, z: 930 } }, 1500).easing(TWEEN.Easing.Cubic.Out).start();
        } else {
            new TWEEN.Tween(mainCamera).to({ position: { x: 0, y: 0, z: 900 } }, 1500).easing(TWEEN.Easing.Cubic.Out).start();
        }

        animatePlanets(scenes[0], { opacity: 1 });


        animateScene(scenes[0], { position: { ...galaxyState.position, x: -50 }, rotation: { ...galaxyState.rotation, y: 0.7, z: scenes[0].rotation.z } }, 1500);
        if (window.innerWidth < 960) {
            animatePlanets(scenes[0], { opacity: 0 }, 1500);
        }

        setTimeout(() => {
            navigate(`/about-the-drop`);
        }, 1500);
    };

    const loadPlanet = (planetInfo: any) => {
        addPlanet(scenes[1], planetInfo);
    };

    const loadWatchParticles = (planetInfo: any) => {
        initWatchParticles(scenes[1], planetInfo);
    };

    const clearState = () => {
        scenes[1].children = [];
    };

    const setNavigationState = () => {
        dispatch({ isFirstNavigation: false });
    };

    useEffect(() => {
        removeMouseDownEvent();
    }, []);

    useEffect(() => {

        clearState();
        setNavigationState();

        if (watchName) getWatchInfo(watchName);

    }, [watchName]);

    useEffect(() => {
        if (planetInfoState) {
            initThree();
            loadWatchParticles(planetInfoState);
            loadPlanet(planetInfoState);
        }
    }, [planetInfoState]);

    if (state.loadingState) return (<Loader />);

    return (
        <section id="watch" className={`watch-container ${visibaleState === true ? 'visible' : ''} ${fadeInState === true ? 'fade-in' : ''}`}>
            <ViewAll />
            <div className={`planet-image-container ${watchInfoState ? watchInfoState.name : null}`}>
                {watchInfoState && <WatchLoader imgUrl={`${process.env.PUBLIC_URL}${watchInfoState.img}`} />}
            </div>
            <div className="planet-info-container">
                <h1>{watchInfoState ? watchInfoState.name : null}</h1>
                {watchInfoState ? getWatchContent(watchInfoState) : null}
                <AboutDropButton action={aboutTheDropWatchAction} />
            </div>
            <div className="planet-shadow">&nbsp;</div>
            <Navigator setFadeInState={setFadeInState} />
        </section>
    );
}

export default Watch;
