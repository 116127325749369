import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

// STATE
import { useGlobalState } from '../../index';

// THREE JS
import { scenes } from '../../Three';
import { galaxyState } from '../../Three/galaxy';
import { animatePlanets } from '../../Three/planets';
import { animateScene } from '../../Three/scene';
import { goBackToExplore, showNonNeededElements } from '../../Three/watches';

// STYLES
import './styles.scss';

function JcobHeader() {

    const navigate = useNavigate();
    const location = useLocation();
    const [state, dispatch] = useGlobalState();

    const navigateTo = () => {
        if (location.pathname === '/explore' && state.isNavigation) {
            goHome();
        } else if (location.pathname === '/about-the-drop' && state.isNavigation) {
            goExplore();
        } else if (location.pathname !== '/' && state.isNavigation) {
            goExploreWatch();
        }
    };

    const goHome = () => {

        dispatch({ isAboutDrop: true, isNavigation: false });

        animateScene(scenes[0], { position: galaxyState.position, rotation: { ...galaxyState.rotation, z: scenes[0].rotation.z } }, 1500);
        animatePlanets(scenes[0], { opacity: 0 }, 1500);

        navigate('/');

    };

    const goExplore = () => {

        dispatch({ isAboutDrop: false, isNavigation: false });

        if (window.innerWidth < 960) {
            animateScene(scenes[0], { position: { x: 0, y: 0, z: 777 }, rotation: { x: -0.7, y: 0, z: scenes[0].rotation.z } }, 1500);
        } else {
            animateScene(scenes[0], { position: { x: 0, y: 0, z: 807 }, rotation: { x: -0.7, y: 0, z: scenes[0].rotation.z } }, 1500);
        }

        animatePlanets(scenes[0], { opacity: 1 }, 1500);

        setTimeout(() => {
            navigate('/explore');
        }, 1500);

    }

    const goExploreWatch = () => {

        showNonNeededElements();

        dispatch({ isAboutDrop: true, isNavigation: false });

        goBackToExplore();

        navigate(`/explore`);
    }

    return (
        <div className="jcob-header-container">
            <img src={`${process.env.PUBLIC_URL}/assets/img/jacob-and-co-logo.png`} id="jcob-logo-element" className="jcob-logo" onClick={() => navigateTo()} />
        </div>
    );
}

export default JcobHeader;
