import * as THREE from 'three';

import { renderer, scenes } from './index';

export let rotateParticlesInterval;
export let pointerMoveInterval;

export function loadBackGroundParticles(scene) {

    const geometry = new THREE.BufferGeometry();
    const vertices = [];

    const textureLoader = new THREE.TextureLoader();
    const sprites = textureLoader.load(`${process.env.PUBLIC_URL}/assets/img/textures/ball.png`);

    for (let i = 0; i < 4500; i++) {

        const x = Math.random() * 2000 - 1000;
        const y = Math.random() * 2000 - 1000;
        const z = Math.random() * 2000 - 1000;

        vertices.push(x, y, z);

    }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));

    const materials = [];
    const parameters = [
        [[0.65, 0.54, 0.32], sprites, 9],
        [[0.75, 0.34, 0.32], sprites, 9],
        [[0.55, 0.34, 0.52], sprites, 7],
    ];

    for (let i = 0; i < parameters.length; i++) {

        const color = parameters[i][0];
        const sprite = parameters[i][1];
        const size = parameters[i][2];

        materials.push(new THREE.PointsMaterial({
            size: size,
            map: sprite,
            blending: THREE.AdditiveBlending,
            transparent: true,
            depthTest: false,
            opacity: 0.4
        }));

        materials[i].color.setHSL(color[0], color[1], color[2]);

        const particles = new THREE.Points(geometry, materials[i]);
        particles.needsUpdate = true;
        particles.name = 'sprites-mesh';

        particles.position.z = 0;

        particles.rotation.x = Math.random() * 6;
        particles.rotation.y = Math.random() * 6;
        particles.rotation.z = Math.random() * 6;

        scene.add(particles);

    }

    rotateParticles(scene);
    registerEvents();

}

export function rotateParticles(scene) {
    if (rotateParticlesInterval) clearInterval(rotateParticlesInterval);
    rotateParticlesInterval = setInterval(() => {
        try {

            scene.children[2].rotation.z -= 0.0015;
            scene.children[3].rotation.z -= 0.0015;
            scene.children[4].rotation.z -= 0.0015;

        } catch (error) {
            console.log('Ops! U were too fast!')
        }
    }, 40)
}

function registerEvents() {
    getGalaxyMesh()
}

function getGalaxyMesh() {
    let mesh = scenes[0].children.find(mesh => mesh.name === 'galaxy-mesh');
    if (mesh) {
        window.addEventListener('pointermove', (event) => onPointerMove(scenes[0], event, mesh));
    } else {
        setTimeout(() => {
            getGalaxyMesh();
        }, 20);
    }
}

function onPointerMove(scene, event, mesh) {

    let x = Math.abs((event.clientX / renderer.domElement.clientWidth) * 2 - 1);
    let y = Math.abs((event.clientY / renderer.domElement.clientHeight) * 2 + 1);

    try {

        let movValX = 0.0005;
        let movValY = 0.0005;

        let rotationX = mesh.rotation.x;
        let rotationY = mesh.rotation.y;

        let leftRight = cursorLeftRightDirection(event);
        let upDown = cursorUpDownDirection(event);

        if (upDown === 'up' && rotationX < 0.03) {

            scene.children[2].rotation.x += movValX;
            scene.children[3].rotation.x += movValX;
            scene.children[4].rotation.x += movValX;
            scene.children[5].rotation.x += movValX;
            scene.children[6].rotation.x += movValX;
            scene.children[7].rotation.x += movValX;

        } else if (upDown === 'down' && rotationX > -0.03) {

            scene.children[2].rotation.x -= movValX;
            scene.children[3].rotation.x -= movValX;
            scene.children[4].rotation.x -= movValX;
            scene.children[5].rotation.x -= movValX;
            scene.children[6].rotation.x -= movValX;
            scene.children[7].rotation.x -= movValX;

        }

        if (leftRight === 'left' && rotationY < 0.03) {

            scene.children[2].rotation.y += movValY;
            scene.children[3].rotation.y += movValY;
            scene.children[4].rotation.y += movValY;
            scene.children[5].rotation.y += movValY;
            scene.children[6].rotation.y += movValY;
            scene.children[7].rotation.y += movValY;

        } else if (leftRight === 'right' && rotationY > -0.03) {

            scene.children[2].rotation.y -= movValY;
            scene.children[3].rotation.y -= movValY;
            scene.children[4].rotation.y -= movValY;
            scene.children[5].rotation.y -= movValY;
            scene.children[6].rotation.y -= movValY;
            scene.children[7].rotation.y -= movValY;

        }

        clearInterval(pointerMoveInterval);
        smoothPointer(scene, mesh, x, y, leftRight, upDown);

    } catch (error) {
        console.log('Ops! U were too fast!')
    }
}

let oldy = 0;

function cursorUpDownDirection(event) {

    let direction;

    if (event.pageY > oldy) {
        direction = "down";
    } else if (event.pageY < oldy) {
        direction = "up";
    }

    oldy = event.pageY;

    return direction;
}

let oldx = 0;

function cursorLeftRightDirection(event) {

    let direction;

    if (event.pageX < oldx) {
        direction = "left"
    } else if (event.pageX > oldx) {
        direction = "right"
    }

    oldx = event.pageX;

    return direction;

}

const smoothPointer = (scene, mesh, x, y, leftRight, upDown) => {

    let counter = 0;

    pointerMoveInterval = setInterval(() => {

        counter += 40;

        try {

            let movValX = 0.0005;
            let movValY = 0.0005;

            let rotationX = mesh.rotation.x;
            let rotationY = mesh.rotation.y;

            if (upDown === 'up' && rotationX < 0.03) {

                scene.children[2].rotation.x += movValX;
                scene.children[3].rotation.x += movValX;
                scene.children[4].rotation.x += movValX;
                scene.children[5].rotation.x += movValX;
                scene.children[6].rotation.x += movValX;
                scene.children[7].rotation.x += movValX;

            } else if (upDown === 'down' && rotationX > -0.03) {

                scene.children[2].rotation.x -= movValX;
                scene.children[3].rotation.x -= movValX;
                scene.children[4].rotation.x -= movValX;
                scene.children[5].rotation.x -= movValX;
                scene.children[6].rotation.x -= movValX;
                scene.children[7].rotation.x -= movValX;

            }

            if (leftRight === 'left' && rotationY < 0.03) {

                scene.children[2].rotation.y += movValY;
                scene.children[3].rotation.y += movValY;
                scene.children[4].rotation.y += movValY;
                scene.children[5].rotation.y += movValY;
                scene.children[6].rotation.y += movValY;
                scene.children[7].rotation.y += movValY;

            } else if (leftRight === 'right' && rotationY > -0.03) {

                scene.children[2].rotation.y -= movValY;
                scene.children[3].rotation.y -= movValY;
                scene.children[4].rotation.y -= movValY;
                scene.children[5].rotation.y -= movValY;
                scene.children[6].rotation.y -= movValY;
                scene.children[7].rotation.y -= movValY;

            }

        } catch (error) {
            console.log('Ops! U were too fast!')
        }

        if (counter > 10000) clearInterval(pointerMoveInterval);

    }, 40);

}

