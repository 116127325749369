import React, { useEffect } from 'react';

// THREE JS
import { preloadGalaxyTextures } from '../Three/galaxy';
import { preloadPlanetsTextures } from '../Three/planets';
import { preloadImages } from '../Three/watches';

// STATE
import { useGlobalState } from '../index';

// STYLES
import './styles.scss';

function Loader() {

    const [state, dispatch] = useGlobalState();

    const preloadTextures = async (): Promise<void> => {

        await preloadPlanetsTextures();
        await preloadGalaxyTextures();
        //await preloadImages();

        dispatch({ loadingState: false });

    };

    useEffect(() => {
        if (state.loadingState) preloadTextures();
    }, []);

    return (
        <div className="loader-container">
            <div className="loader-logo">
                <div className="loader-circle"></div>
            </div>
        </div>
    );
}

export default Loader;