import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// TWEEN
import * as TWEEN from '@tweenjs/tween.js';

// THREE JS
import { animateScene } from '../Three/scene';
import { galaxyState } from '../Three/galaxy';
import { planets, animatePlanets, detectPlanet } from '../Three/planets';
import { mainCamera, mouse, raycaster, renderer, scenes } from '../Three/index';

// STATE
import { useGlobalState } from '../index';

// MODULES
import AboutDropButton from '../shared/AboutDropButton';
import ExploreAllButton from '../shared/ExploreAllButton';
import Loader from '../Loader';

// STYLES
import './styles.scss';

// UTILS
import { playAudio } from '../shared/utils';

// CONSTANTS
export const BUTTON_TEXT = 'Back to intro';

function Explore() {

    const navigate = useNavigate();
    const [state, dispatch] = useGlobalState();

    let inside = false;
    let navigating = false;

    const registerEvents = () => {

        dispatch({ onMouseDownRef: onMouseDown });

        const jcobElement = document.getElementById('jcob-container-elem');
        jcobElement?.addEventListener('pointermove', detectPlanet);
        jcobElement?.addEventListener("mousedown", onMouseDown);
    }

    const registerPlanetAudioEvent = () => {

        dispatch({ planetAudioEventRef: planetAudioEvent });

        const jcobElement = document.getElementById('jcob-container-elem');
        jcobElement?.addEventListener('pointermove', planetAudioEvent);

    }

    const removePlanetAudioEvent = () => {
        const jcobElement = document.getElementById('jcob-container-elem');
        jcobElement?.removeEventListener('pointermove', state.planetAudioEventRef);
    }

    const removeMouseDownEvent = () => {
        const jcobElement = document.getElementById('jcob-container-elem');
        jcobElement?.removeEventListener('mousedown', state.onMouseDownRef);
    }

    const onMouseDown = (event: any) => {

        event.preventDefault()

        mouse.x = ((event.clientX) / renderer.domElement.clientWidth) * 2 - 1;
        mouse.y = - ((event.clientY - 61) / renderer.domElement.clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, mainCamera);

        let planetGroup = scenes[0].children.find((children: any) => children.name === 'planet-group');
        let intersects = raycaster.intersectObjects(planetGroup.children);

        let planetFound = intersects.find((planet: any) => planet.object.kind === 'planet');

        if (planetFound && !navigating) {

            navigating = true;

            dispatch({ isNavigation: false });

            removeMouseDownEvent();

            const planetContainer = document.getElementById('planet-name') as HTMLElement;
            if (planetContainer) planetContainer.innerHTML = '';

            const point = intersects[0].point;
            const planet = planets.find(planet => planet.name === intersects[0].object.name);

            new TWEEN.Tween(mainCamera).to({ position: { x: intersects[0].object.position.x, y: point.y, z: 810 } }, 1500).easing(TWEEN.Easing.Cubic.In).start();

            let planetGroup = scenes[0].children.find((children: any) => children.name === 'planet-group');
            planetGroup.children.forEach((planetScene: any, i: number) => {
                new TWEEN.Tween(planetScene.material).to({ opacity: 0 }, 500).easing(TWEEN.Easing.Cubic.In).delay(1000).start().onComplete(() => {
                    navigate(`/${planet?.name}`);
                });
            });
        }
    };

    const planetAudioEvent = (event: any) => {

        event.preventDefault();

        mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
        mouse.y = - ((event.clientY - 61) / renderer.domElement.clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, mainCamera);

        let planetGroup = scenes[0].children.find((children: any) => children.name === 'planet-group');
        let intersects = raycaster.intersectObjects(planetGroup.children);

        if (intersects.length > 0 && !inside) {
            inside = true;
            state.audio && playAudio();
        } else if (intersects.length === 0) {
            inside = false;
        }
    }

    const initThree = () => {

        if (window.innerWidth < 960) {
            scenes[0].position.set(0, 0, 777);
        } else {
            scenes[0].position.set(0, 0, 807);
        }
        scenes[0].rotation.set(-0.7, 0, 0);

        let planetGroup = scenes[0].children.find((children: any) => children.name === 'planet-group');
        planetGroup.children.forEach((children: any, i: number) => {
            children.material.opacity = 1;
        });

    };

    const setNavigationState = (delay: number) => {
        setTimeout(() => {
            dispatch({ isFirstNavigation: false, isNavigation: true });
        }, delay);
    }

    const goHome = () => {
        if (state.isNavigation) {

            removeMouseDownEvent();
            dispatch({ isNavigation: false });

            animateScene(scenes[0], { position: galaxyState.position, rotation: { ...galaxyState.rotation, z: scenes[0].rotation.z } }, 1500);
            animatePlanets(scenes[0], { opacity: 0 }, 1500);

            navigate('/');
        }
    }

    const aboutTheDropExploreAction = () => {
        if (state.isNavigation) {

            removeMouseDownEvent();
            removePlanetAudioEvent();

            dispatch({ isNavigation: false });

            animateScene(scenes[0], { position: { ...galaxyState.position, x: -50 }, rotation: { ...galaxyState.rotation, y: 0.7, z: scenes[0].rotation.z } }, 1500);
            animatePlanets(scenes[0], { opacity: 1 }, 750);

            navigate('/about-the-drop');

        }
    }

    useEffect(() => {

        setNavigationState(0);
        initThree();
        registerEvents();
        registerPlanetAudioEvent();

    }, []);

    useEffect(() => {
        if (state.audio) {
            removePlanetAudioEvent();
            registerPlanetAudioEvent();
        } else {
            removePlanetAudioEvent();
        }
    }, [state.audio]);

    if (state.loadingState) return (<Loader />);


    return (
        <div id="explore" className="explore-container">
            <div id="planet-name" className="planet-name-container"></div>
            <ExploreAllButton arrowDirection="left" buttonText={BUTTON_TEXT} action={goHome} />
            <AboutDropButton action={aboutTheDropExploreAction} />
        </div>
    );
}

export default Explore;

