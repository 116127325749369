
const audio = new Audio(`${process.env.PUBLIC_URL}/assets/audio/click-sound.wav`);
audio.volume = 0.6;

export async function playAudio() {
    try {
        if (!audio.paused) {
            await audio.pause();
            audio.currentTime = 0;
            await audio.play();
        } else {
            await audio.play();
        }
    } catch (error) {
        console.log('Ops you must interact!');
    }
}


