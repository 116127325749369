import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// THREE JS
import { scenes } from '../Three/index';
import { galaxyState, galaxyStateResponsive } from '../Three/galaxy';
import { animatePlanets } from '../Three/planets';
import { animateScene } from '../Three/scene';

// STATE
import { useGlobalState } from '../index';

// MODULES
import AboutDropButton from '../shared/AboutDropButton';
import ExploreAllButton from '../shared/ExploreAllButton';
import Loader from '../Loader';
import TheStoryButton from '../shared/TheStoryButton';

// STYLES
import './styles.scss';

// CONSTANTS
export const EXPLORE_ALL = 'Explore All';

function Home() {

    const [state, dispatch] = useGlobalState();
    const [exploreVisibleState, setExploreVisibleState] = useState(false);

    const navigate = useNavigate();

    const initThree = () => {
        setExploreVisibleState(true);
    };

    const setNavigationState = (delay: number) => {
        setTimeout(() => {
            dispatch({ isNavigation: true });
        }, delay);
    };

    const exploreAll = () => {
        if (state.isNavigation) {

            state.isFirstNavigation && !state.audio && dispatch({ audio: true });

            dispatch({ isFirstNavigation: false });

            setExploreVisibleState(false);

            if (window.innerWidth < 960) {
                animateScene(scenes[0], { position: galaxyStateResponsive.exploreInitialState.position, rotation: { ...galaxyStateResponsive.exploreInitialState.rotation, z: scenes[0].rotation.z } }, 1500);
            } else {
                animateScene(scenes[0], { position: galaxyState.exploreInitialState.position, rotation: { ...galaxyState.exploreInitialState.rotation, z: scenes[0].rotation.z } }, 1500);
            }

            animatePlanets(scenes[0], { opacity: 1 }, 1500);

            setTimeout(() => {
                navigate('/explore');
            }, 1500);

        }
    };

    const aboutTheDropHomeAction = () => {
        if (state.isNavigation) {

            state.isFirstNavigation && !state.audio && dispatch({ audio: true });

            dispatch({ isFirstNavigation: false, isFromHome: true });

            setExploreVisibleState(false);

            animateScene(scenes[0], { position: { ...galaxyState.position, x: -50 }, rotation: { ...galaxyState.rotation, y: 0.7, z: scenes[0].rotation.z } }, 3000);
            animatePlanets(scenes[0], { opacity: 1 }, 3000);

            if (window.innerWidth < 960) {
                setTimeout(() => {
                    animatePlanets(scenes[0], { opacity: 0 }, 1500);
                }, 1500);
            }

            setTimeout(() => {
                navigate('/about-the-drop');
            }, 3000);
        }
    };

    useEffect(() => {
        if (!state.loadingState) {
            setNavigationState(1500);
            initThree();
        }
    }, [state.loadingState]);

    if (state.loadingState) return (<Loader />);

    return (
        <div id="home" className="home-container">
            <TheStoryButton visibleState={exploreVisibleState} />
            <AboutDropButton action={aboutTheDropHomeAction} />
            <section className={`home-content-container ${exploreVisibleState === true ? 'show' : 'hide'}`}>
                <div className="home-title">
                    <h1>Astronomia Metaverso</h1>
                </div>
                <div className="home-content">
                    <p>
                        Jacob & Co. started 36 years ago with one clear vision: to be different. That vision has been executed every
                        year since. Being different and creating your own path instead of following others takes a certain fearlessness.
                        That is what this company’s foundation has been built on.
                    </p>
                    <p>
                        Astronomia Metaverso marks Jacob&Co.’s fearless venture into the metaverse. The collection draws on their
                        storied Astronomia line, while pushing it into a digital, crypto-native future.
                    </p>
                </div>
                <div className="actions-container">
                    <ExploreAllButton arrowDirection="right" buttonText={EXPLORE_ALL} action={exploreAll} />
                </div>
            </section>
        </div>
    );

}

export default Home;

