import React from 'react';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';

// UTILS
import { playAudio } from '../utils';

function AboutDropButton({ action }: { action: any }) {

    const [state, dispatch] = useGlobalState();

    return (
        <div className={`about-drop-button-container responsive ${state.isNavigation ? 'active' : ''}`} onClick={() => action()} >
            <button className="about-drop-button-content" onMouseEnter={() => state.audio && playAudio()}>About the Drop
                <div className="center-con">
                    <div className="round">
                        <div id="cta">
                            <span className="arrow primera next"></span>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
}

export default AboutDropButton;