import * as THREE from 'three';
import { vertexShader, fragmentShaderItemBackground, fragmentShaderFogBackground } from './shaders';
import { clock, textures } from './index';

export const galaxyState = {
    position: { x: 50, y: 6, z: 780 },
    rotation: { x: -0.5, y: -0.7, z: 0 },
    exploreInitialState: {
        position: { x: 0, y: 0, z: 807 },
        rotation: { x: -0.7, y: 0, z: 0 }
    }
}

export const galaxyStateResponsive = {
    position: { x: 50, y: 6, z: 780 },
    rotation: { x: -0.5, y: -0.7, z: 0 },
    exploreInitialState: {
        position: { x: 0, y: 0, z: 777 },
        rotation: { x: -0.7, y: 0, z: 0 }
    }
}

export function getGalaxyState() {
    if (window.innerWidth < 980) {
        return galaxyStateResponsive;
    } else {
        return galaxyState;
    }
}

const galaxyTexture = "/assets/img/textures/galaxy-def-full-v4.jpg";

export function initGalaxyParticles(scene) {
    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(`${process.env.PUBLIC_URL}${galaxyTexture}`, (galaxyTexture) => {

        const galaxyMesh = galaxyPixelExtraction(galaxyTexture);
        const galaxyFogMesh = galaxyFogPixelExtraction(galaxyTexture);

        scene.add(galaxyMesh);
        scene.add(galaxyFogMesh);

        rotateGalaxy(scene);
        //registerGalaxyEvents(scene);

    });
}

export function galaxyPixelExtraction(texture) {

    const width = texture.source.data.width;
    const height = texture.image.height;
    const totalPoints = width * height;

    const img = texture.image;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    ctx.scale(1, -1);
    ctx.drawImage(img, 0, 0, width, height * -1);

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const arrayOfColors = Float32Array.from(imgData.data);

    return loadGalaxyParticles(width, height, totalPoints, arrayOfColors, texture);

};

export function galaxyFogPixelExtraction(texture) {

    const width = texture.source.data.width;
    const height = texture.image.height;
    const totalPoints = width * height;

    const img = texture.image;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;

    ctx.scale(1, -1);
    ctx.drawImage(img, 0, 0, width, height * -1);

    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const arrayOfColors = Float32Array.from(imgData.data);

    return loadGalaxyFogParticles(width, height, totalPoints, arrayOfColors, texture);

};

export function loadGalaxyParticles(width, height, totalPoints, arrayOfColors, texture) {

    const geometryParticles = new THREE.InstancedBufferGeometry();

    const positions = new THREE.BufferAttribute(new Float32Array(4 * 3), 3);
    positions.setXYZ(0, -0.5, 0.5, 0.0);
    positions.setXYZ(1, 0.5, 0.5, 0.0);
    positions.setXYZ(2, -0.5, -0.5, 0.0);
    positions.setXYZ(3, 0.5, -0.5, 0.0);
    geometryParticles.setAttribute('position', positions);

    const uvs = new THREE.BufferAttribute(new Float32Array(4 * 2), 2);
    uvs.setXYZ(0, 0.0, 0.0);
    uvs.setXYZ(1, 1.0, 0.0);
    uvs.setXYZ(2, 0.0, 1.0);
    uvs.setXYZ(3, 1.0, 1.0);
    geometryParticles.setAttribute('uv', uvs);

    geometryParticles.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1));

    const offsets = new Float32Array((totalPoints * 3) / 3);
    const indices = new Uint16Array(totalPoints / 3);
    const angles = new Float32Array(totalPoints / 3);

    for (let i = 0, j = 0; i < totalPoints; i++) {
        if (arrayOfColors[i * 4 + 0] <= 20) continue;
        offsets[j * 3 + 0] = i % width;
        offsets[j * 3 + 1] = Math.floor(i / width);
        indices[j] = i;
        angles[j] = Math.random() * Math.PI;
        j++;
    };

    geometryParticles.setAttribute('offset', new THREE.InstancedBufferAttribute(offsets, 3, false));
    geometryParticles.setAttribute('angle', new THREE.InstancedBufferAttribute(angles, 1, false));
    geometryParticles.setAttribute('pindex', new THREE.InstancedBufferAttribute(indices, 1, false));

    const uniforms = {
        uTime: { value: 0 },
        uRandom: { value: 85 },
        uDepth: { value: 7.0 },
        uSize: { value: 1.0 },
        uTextureSize: { value: new THREE.Vector2(width, height) },
        uTexture: { value: texture },
        uTouch: { value: null },
        uAlphaCircle: { value: 0 },
        uAlphaSquare: { value: 0.0 },
        uCircleORsquare: { value: 0.0 },
    };

    const materialParticles = new THREE.RawShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader(),
        fragmentShader: fragmentShaderItemBackground(),
        depthTest: false,
        transparent: true,
        // blending: THREE.AdditiveBlending
    });

    const particles = new THREE.Mesh(geometryParticles, materialParticles)
    particles.needsUpdate = true;
    particles.name = 'galaxy-mesh';
    particles.position.z = -15;
    particles.rotation.z = 10;

    return particles;

};

export function loadGalaxyFogParticles(width, height, totalPoints, arrayOfColors, texture) {

    const geometryParticles = new THREE.InstancedBufferGeometry();

    const positions = new THREE.BufferAttribute(new Float32Array(4 * 3), 3);
    positions.setXYZ(0, -0.5, 0.5, 0.0);
    positions.setXYZ(1, 0.5, 0.5, 0.0);
    positions.setXYZ(2, -0.5, -0.5, 0.0);
    positions.setXYZ(3, 0.5, -0.5, 0.0);
    geometryParticles.setAttribute('position', positions);

    const uvs = new THREE.BufferAttribute(new Float32Array(4 * 2), 2);
    uvs.setXYZ(0, 0.0, 0.0);
    uvs.setXYZ(1, 1.0, 0.0);
    uvs.setXYZ(2, 0.0, 1.0);
    uvs.setXYZ(3, 1.0, 1.0);
    geometryParticles.setAttribute('uv', uvs);

    geometryParticles.setIndex(new THREE.BufferAttribute(new Uint16Array([0, 2, 1, 2, 3, 1]), 1));

    const offsets = new Float32Array((totalPoints * 3) / 3);
    const indices = new Uint16Array(totalPoints / 3);
    const angles = new Float32Array(totalPoints / 3);
    for (let i = 0, j = 0; i < totalPoints; i++) {
        if (arrayOfColors[i * 4 + 0] <= 35) continue;
        offsets[j * 3 + 0] = i % width;
        offsets[j * 3 + 1] = Math.floor(i / width);
        indices[j] = i;
        angles[j] = Math.random() * Math.PI;
        j++;
    };

    geometryParticles.setAttribute('offset', new THREE.InstancedBufferAttribute(offsets, 3, false));
    geometryParticles.setAttribute('angle', new THREE.InstancedBufferAttribute(angles, 1, false));
    geometryParticles.setAttribute('pindex', new THREE.InstancedBufferAttribute(indices, 1, false));

    const uniforms = {
        uTime: { value: 0 },
        uRandom: { value: 23 },
        uDepth: { value: 5 },
        uSize: { value: 3.45 },
        uTextureSize: { value: new THREE.Vector2(width, height) },
        uTexture: { value: texture },
        uTouch: { value: null },
        uAlphaCircle: { value: 0 },
        uAlphaSquare: { value: 0.0 },
        uCircleORsquare: { value: 0.0 },
    };

    const materialParticles = new THREE.RawShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader(),
        fragmentShader: fragmentShaderFogBackground(),
        depthTest: false,
        transparent: true,

    });

    const particles = new THREE.Mesh(geometryParticles, materialParticles)
    particles.needsUpdate = true;
    particles.name = 'galaxy-fog-mesh';
    particles.position.z = -16;
    particles.rotation.z = 20;

    return particles;
};

export function rotateGalaxy(scene) {
    setInterval(() => {

        scene.children[6].rotation.z -= 0.0009;
        scene.children[7].rotation.z -= 0.001;

        const test = clock.getDelta();
        scene.children[6].material.uniforms.uTime.value += test;
        scene.children[7].material.uniforms.uTime.value += test / 2;

    }, 38);
}

export const preloadGalaxyTextures = async () => {
    const textureLoader = new THREE.TextureLoader();
    return new Promise((resolve, reject) => {
        textureLoader.load(`${process.env.PUBLIC_URL}${galaxyTexture}`, (galaxyTexture) => {
            galaxyTexture.name = 'galaxy-mesh';
            textures.push(galaxyTexture);
            resolve();
        });
    });
};