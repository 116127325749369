import React from 'react';

// STATE
import { useGlobalState } from '../../index';

// STYLES
import './styles.scss';

// UTILS
import { playAudio } from '../utils';

const THE_STORY_URL = `${process.env.PUBLIC_URL}/story`;

const TheStoryButton = ({ visibleState }: { visibleState: boolean }) => {

    const [state, dispatch] = useGlobalState();

    const navigateTo = () => {
        window.location.href = THE_STORY_URL;
    }

    return (
        <div className={`the-story-button-container ${visibleState ? 'active' : ''}`} onClick={() => navigateTo()}>
            <button className="the-story-button" onMouseEnter={() => state.isNavigation && state.audio && playAudio()}>
                <div className="center-con">
                    <div className="round left">
                        <div id="cta">
                            <span className="arrow primera next left"></span>
                        </div>
                    </div>
                </div>
                The Story
            </button>
        </div>
    );
}

export default TheStoryButton;