import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

// THREE JS
import { mainCamera, mouse, raycaster, renderer, scenes, textures } from './index';

const planetGeometry = new THREE.SphereGeometry(120, 64, 64);
const planetsGeometry = setPlanetGeometry();

let rotatePlanetsInterval;
let rotatePlanetsSceneInterval;

export const planets = [
    {
        name: 'Mercury',
        url: 'url',
        img: `/assets/img/planet-textures/2k_mercury.jpg`,
        img_high: `/assets/img/planet-textures/high_mercury.jpg`,
        img_background: '/assets/img/textures/item-background-mercury.jpg',
        position: { x: 15, y: 35, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xb7b6b5
    },
    {
        name: 'Venus',
        url: 'url',
        img: `/assets/img/planet-textures/2k_venus_surface.jpg`,
        img_high: `/assets/img/planet-textures/high_venus_surface.jpg`,
        img_background: `/assets/img/textures/item-background-venus.jpg`,
        position: { x: 35, y: 15, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xec7a28
    },
    {
        name: 'Earth',
        url: 'url',
        img: `/assets/img/planet-textures/2k_earth.jpg`,
        img_high: `/assets/img/planet-textures/high_earth.jpg`,
        img_background: `/assets/img/textures/item-background-earth.jpg`,
        position: { x: 35, y: -15, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0x25478b
    },
    {
        name: 'Mars',
        url: 'url',
        img: `/assets/img/planet-textures/2k_mars.jpg`,
        img_high: `/assets/img/planet-textures/high_mars.jpg`,
        img_background: `/assets/img/textures/item-background.jpeg`,
        position: { x: 15, y: -35, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xd06243
    },
    {
        name: 'Jupiter',
        url: 'url',
        img: `/assets/img/planet-textures/2k_jupiter.jpg`,
        img_high: `/assets/img/planet-textures/high_jupiter.jpg`,
        img_background: `/assets/img/textures/item-background-jupiter.jpg`,
        position: { x: -15, y: -35, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xa6998a
    },
    {
        name: 'Saturn',
        url: 'url',
        img: `/assets/img/planet-textures/2k_saturn.jpg`,
        img_high: `/assets/img/planet-textures/high_saturn.jpg`,
        img_background: `/assets/img/textures/item-background-saturn.jpg`,
        position: { x: -35, y: -15, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xd2c69e
    },
    {
        name: 'Uranus',
        url: 'url',
        img: `/assets/img/planet-textures/2k_uranus.jpg`,
        img_high: `/assets/img/planet-textures/high_uranus.jpg`,
        img_background: `/assets/img/textures/item-background-uranus.jpg`,
        position: { x: -35, y: 15, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0xd9ffff
    },
    {
        name: 'Neptune',
        url: 'url',
        img: `/assets/img/planet-textures/2k_neptune.jpg`,
        img_high: `/assets/img/planet-textures/high_neptune.jpg`,
        img_background: `/assets/img/textures/item-background-neptune.jpg`,
        position: { x: -15, y: 35, z: 1 },
        rotationSpeed: 0.015,
        glow_color: 0x5f83f5
    }
];

export function setPlanetGeometry() {
    if (window.innerWidth < 400) {
        return new THREE.SphereGeometry(6.5, 64, 64);
    } else if (window.innerWidth <= 600) {
        return new THREE.SphereGeometry(6, 64, 64);
    } else if (window.innerWidth > 600) {
        return new THREE.SphereGeometry(5, 64, 64);
    }
}

export const planetsState = {
    position: { x: 30, y: 6, z: 815 },
    rotation: { x: -0.5, y: -0.7, z: 0 },
    exploreInitialState: {
        position: { x: 0, y: 8, z: 820 },
        rotation: { x: -0.7, y: 0, z: 0 }
    },
    exploreInitialStateResponsive: {
        position: { x: 0, y: 8, z: 820 },
        rotation: { x: -0.7, y: 0, z: 0 }
    }
}

export const planetState = {
    initialPosition: { x: -280, y: 0, z: 700 },
    activePosition: { x: 0, y: 0, z: 700 },
    unactivePosition: { x: 280, y: 0, z: 700 },
}

export function addPlanets(scene, opacity = 1) {

    const textureLoader = new THREE.TextureLoader();
    const group = new THREE.Group();
    group.name = 'planet-group';

    const glow = textureLoader.load(`${process.env.PUBLIC_URL}/assets/img/glow.png`);
    var glowMaterial = new THREE.SpriteMaterial(
        {
            map: glow,
            useScreenCoordinates: false,
            color: 0x00cfff,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            opacity: opacity
        });

    planets.forEach(planet => {

        let texture = textures.find(texture => texture.name === `${planet.name}-small`);

        if (!texture) {
            texture = textureLoader.load(`${process.env.PUBLIC_URL}${planet.img}`);
            texture.name = `${planet.name}-small`;
            textures.push(texture);
        }

        const material = new THREE.MeshLambertMaterial({
            color: '#dcdcdc',
            map: texture,
            transparent: true,
            opacity: opacity,
            blending: THREE.NormalBlending,
        });

        const newPlanet = new THREE.Mesh(planetsGeometry, material);
        newPlanet.name = planet.name;
        newPlanet.kind = 'planet';
        newPlanet.position.x = planet.position.x;
        newPlanet.position.y = planet.position.y;
        newPlanet.position.z = 17;
        newPlanet.rotation.x = 1.3;
        newPlanet.rotationSpeed = planet.rotationSpeed;


        const glowSprite = new THREE.Sprite(glowMaterial);
        glowSprite.name = 'glow-mesh';
        glowSprite.position.x = planet.position.x;
        glowSprite.position.y = planet.position.y + 1;
        glowSprite.position.z = 17;
        glowSprite.scale.set(15, 15, 15);

        if (planet.name === 'Saturn') {
            const ringMesh = createRings(opacity);
            group.add(ringMesh);
        }

        group.add(newPlanet);
        group.add(glowSprite);

    });

    group.position.z = -2;
    group.rotation.x = 0.0;


    scene.add(group);

    //rotatePlanets(scene);

}

function createRings(opacity = 1) {

    const textureLoader = new THREE.TextureLoader();

    const texture = textureLoader.load(`${process.env.PUBLIC_URL}/assets/img/planet-textures/2k_saturn_ring_alpha.png`);

    const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        color: 0xffffff,
        transparent: true,
        opacity: opacity,
    });

    const geometry = new THREE.RingBufferGeometry(0.5, 9, 64);
    var pos = geometry.attributes.position;
    var v3 = new THREE.Vector3();

    for (let i = 0; i < pos.count; i++) {
        v3.fromBufferAttribute(pos, i);
        geometry.attributes.uv.setXY(i, v3.length() < 4 ? 0 : 1, 1);
    }

    const mesh = new THREE.Mesh(geometry, material);

    mesh.name = 'saturn-ring';

    mesh.position.x = -35;
    mesh.position.y = -15;
    mesh.position.z = 17;
    mesh.rotation.x = -0.25;
    mesh.rotation.y = -0.10;

    return mesh;

}

export function addPlanet(scene, planetInfo) {

    let texture = textures.find(texture => texture.name === `${planetInfo.name}-big`);
    if (!texture) {
        const textureLoader = new THREE.TextureLoader();
        textureLoader.load(`${process.env.PUBLIC_URL}${planetInfo.img_high}`, (texture) => {

            texture.name = `${planetInfo.name}-big`;
            textures.push(texture);

            const material = new THREE.MeshBasicMaterial({
                color: '#dcdcdc',
                map: texture,
                transparent: true,
                opacity: 1,
            });

            const newPlanet = new THREE.Mesh(planetGeometry, material);
            newPlanet.name = 'planet-mesh';
            newPlanet.needsUpdate = true;
            newPlanet.position.set(-50, -110, 0);
            newPlanet.rotation.x = 5;
            newPlanet.renderOrder = 1;

            scene.add(newPlanet);

            rotateWatchPlanet(newPlanet);

        });

    } else {

        const material = new THREE.MeshBasicMaterial({
            color: '#dcdcdc',
            map: texture,
            transparent: true,
            opacity: 1
        });

        const newPlanet = new THREE.Mesh(planetGeometry, material);
        newPlanet.needsUpdate = true;
        newPlanet.position.set(-50, -110, 0);
        newPlanet.rotation.x = 5;

        scene.add(newPlanet);
        rotateWatchPlanet(newPlanet);

    }
}

export function rotateWatchPlanet(planetMesh) {
    if (planetMesh) {
        if (rotatePlanetsSceneInterval) clearInterval(rotatePlanetsSceneInterval);
        rotatePlanetsSceneInterval = setInterval(() => {
            try {
                planetMesh.rotation.z += 0.005;
            } catch (error) {
                console.log('Ops! U were too fast!');
            }
        }, 40);
    } else {
        setTimeout(() => {
            rotateWatchPlanet(planetMesh);
        }, 20);
    }
}

export function rotatePlanets(scene) {
    let planetGroup = scene.children.find(children => children.name === 'planet-group');
    if (rotatePlanetsInterval) clearInterval(rotatePlanetsInterval)
    rotatePlanetsInterval = setInterval(() => {
        planetGroup.children?.forEach(planet => {
            if (planet.name !== 'saturn-ring') planet.rotation.y += planet.rotationSpeed;
        });
    }, 40);
}

export function animatePlanets(scene, config, time) {
    let planetGroup = scene.children.find(children => children.name === 'planet-group');
    planetGroup.children.forEach((children, i) => {
        new TWEEN.Tween(children.material).to(config, time).easing(TWEEN.Easing.Cubic.Out).start();
    });
}

export const detectPlanet = (event) => {

    event.preventDefault();

    const planetContainer = document.getElementById('planet-name');

    mouse.x = ((event.clientX) / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = - ((event.clientY - 61) / renderer.domElement.clientHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, mainCamera);

    let planetGroup = scenes[0].children.find(children => children.name === 'planet-group');
    let intersects = raycaster.intersectObjects(planetGroup.children);

    let planetFound = intersects.find(intersect => intersect.object.kind === 'planet');

    if (planetFound && planetFound.object.kind === 'planet' && planetContainer) {
        if (intersects[0].object.name === 'saturn-ring') {
            planetContainer.innerHTML = 'Saturn';
        } else {
            planetContainer.innerHTML = planetFound.object.name;
        }
    } else if (planetContainer) {
        planetContainer.innerHTML = '';
    }
}

const preloadTexture = (textureLoader, planet) => {
    return new Promise((resolve, reject) => {
        textureLoader.load(`${process.env.PUBLIC_URL}${planet.img_background}`, (watchBackgroundTexture) => {
            watchBackgroundTexture.name = `${planet.name}-watch-particles`;
            textures.push(watchBackgroundTexture);
            resolve();
        });
    })
};

export const preloadPlanetsTextures = async () => {
    const textureLoader = new THREE.TextureLoader();
    await Promise.all(planets.map(planet => preloadTexture(textureLoader, planet)));
};

export default planets;