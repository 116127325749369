import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

import { scenes } from './index';

export function createScene(p, r) {

    const scene = new THREE.Scene();

    scene.position.set(p.x, p.y, p.z);
    scene.rotation.set(r.x, r.y, r.z);

    scenes.push(scene);

    return scene;

}

export function addPointLight(scene) {

    const light = new THREE.PointLight(0xff0000, 1, 100);
    light.position.set(50, 50, 50);
    scene.add(light);
}

export function animateScene(scene, config, time) {
    new TWEEN.Tween(scene).to(config, time).easing(TWEEN.Easing.Cubic.Out).start();
}
