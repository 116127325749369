import React from 'react';

// STYLES
import './styles.css';

function Header() {

    return (
        <header id="header" className="header-sticky header-logged-out">
            <div className="header-wrapper">
                <div className="search-container">
                    <div className="search">
                        <div className="search-icon">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/search-icon-dark.svg`} alt="Search icon" />
                        </div>
                        <div className="search-field">
                            <input type="text" name="" placeholder="Search UNXD..." />
                        </div>
                        <div className="search-close">
                            <a>
                                <img src={`${process.env.PUBLIC_URL}/assets/img/close-icon-grey.svg`} alt="Close icon" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="header-container">
                    <div className="header-logo">
                        <a href="https://unxd.com/">
                            <img src={`${process.env.PUBLIC_URL}/assets/img/logo_dark.png`} width="111px" alt="UNXD Logo" />
                        </a>
                    </div>
                    <div className="header-nav">
                        <ul className="header-nav-items">
                            <li className="header-nav-item">
                                <a href="https://unxd.com/drops" className="underline-animation underline-animation--menu">Drops</a>
                            </li>
                            <li className="header-nav-item">
                                <a href="https://unxd.com/marketplace" className="underline-animation underline-animation--menu">Marketplace</a>
                            </li>

                        </ul>
                    </div>
                    <div className="header-icons">
                        <div className="header-icon header-search">
                            {/* <a >
                                <img src={`${process.env.PUBLIC_URL}assets/img/search-icon-dark.svg`} alt="Search icon" />
                            </a> */}
                        </div>
                        <div className="header-icon header-buttons">
                            <div className="buttons-wrapper buttons-wrapper-small">
                                <a href="https://unxd.com/auth/sign-up" className="button button--primary button--small">Sign Up</a>
                                <a href="https://unxd.com/auth/login" className="button button--secondary button--small">Login</a>
                            </div>
                        </div>
                        <div className="header-icon header--hamburger">
                            <input id="hamburger" className="hamburger" type="checkbox" />
                            <label className="hamburger" htmlFor="hamburger">
                                <i></i>
                            </label>
                            <section className="drawer-list">
                                <div className="drawer-list__header header-logo header-logo--mobile">
                                    <a href="#">
                                        <img src={`${process.env.PUBLIC_URL}/assets/img/logo_light.png`} width="111px" alt="UNXD Logo" />
                                    </a>
                                </div>
                                <hr className="mobile-seperator mobile-seperator--full" />
                                <div className="drawer-list__actions">
                                    <div className="drawer-list__left">
                                        <a href="https://unxd.com/auth/sign-up" className="button button--secondary">Sign up</a>
                                    </div>
                                    <div className="drawer-list__right">
                                        <a href="https://unxd.com/auth/login" className="button button--primary button--bordered">Login</a>

                                    </div>
                                    <hr className="mobile-seperator mobile-seperator--actions" />
                                </div>

                                <ul>
                                    <li><a href="https://unxd.com/drops"><h2>Drops</h2></a></li>
                                    <li><a href="https://unxd.com/marketplace"><h2>Marketplace</h2></a></li>
                                </ul>
                                <ul className="header-widget-nav">
                                    <li className="header-widget-nav__item">
                                        <a href="https://unxd.com/about">About</a>
                                    </li>
                                    <li className="header-widget-nav__item">
                                        <a href="https://help.unxd.com/en/">Helpcenter</a>
                                    </li>
                                </ul>
                                <div className="footer-widget-social-icons widget-social-icons--mobile-nav">
                                    <div className="footer-widget-social-icon social-icon-twitter">
                                        <a href="https://twitter.com/unxd_nft">
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/twitter-logo-light.svg`} width="24px" height="24px" alt="Twitter logo" />
                                        </a>
                                    </div>
                                    <div className="footer-widget-social-icon social-icon-discord">
                                        <a href="https://discord.com/invite/unxd">
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/discord_white.svg`} width="24px" height="24px" alt="Discord logo" />
                                        </a>
                                    </div>
                                    <div className="footer-widget-social-icon social-icon-instagram">
                                        <a href="https://www.instagram.com/unxd_nft/">
                                            <img src={`${process.env.PUBLIC_URL}/assets/img/instagram_white.svg`} width="24px" height="24px" alt="Instagram logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className="mobile-menu-links">
                                    <a href="https://unxd.com/privacy">Privacy</a>
                                    <a href="https://unxd.com/terms">Terms of Service</a>
                                </div>

                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
