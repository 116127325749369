import React, { useEffect, useState } from 'react';

// STATE
import { useGlobalState } from '../index';

// MODULES
import Loader from '../Loader';

// STYLES
import './styles.scss';

function Sound() {

    const [state, dispatch] = useGlobalState();

    const [audio] = useState(new Audio(`${process.env.PUBLIC_URL}/assets/audio/jcobv4.ogg`));
    audio.volume = 0.1;
    audio.loop = true;

    const playAudio = () => {
        dispatch({ audio: !state.audio });
    }

    useEffect(() => {
        !state.audio ? audio.pause() : audio.play();
    }, [state.audio]);

    if (state.loadingState) return (<Loader />);

    return (
        <div className="audio-container">
            <button className={`audio-button ${state.audio ? 'active' : ''}`} onClick={() => playAudio()}>AUDIO
                <div className="center-con">
                    <div className="round">
                        <div id="cta">
                            <span className="arrow primera next"></span>
                        </div>
                    </div>
                </div>
            </button>
        </div>
    );
};

export default Sound;