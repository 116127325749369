import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";

// ROUTER
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import { init, animate } from "./Three/index.js";

init();
animate();

export interface GlobalState {
  audio: boolean;
  isFirstNavigation: boolean;
  isNavigation: boolean;
  loadingState: boolean;
}

const defaultGlobalState: GlobalState = {
  audio: false,
  isFirstNavigation: true,
  isNavigation: true,
  loadingState: true,
};

const GlobalStateContext = React.createContext(defaultGlobalState);
const DispatchStateContext = React.createContext<any>(undefined);

type GlobalProviderProps = { children: React.ReactNode };

const GlobalStateProvider = ({ children }: GlobalProviderProps) => {
  const [state, dispatch] = React.useReducer(
    (state: any, newValue: any) => ({ ...state, ...newValue }),
    defaultGlobalState
  );

  return (
    <GlobalStateContext.Provider value={state}>
      <DispatchStateContext.Provider value={dispatch}>
        {children}
      </DispatchStateContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => [
  React.useContext(GlobalStateContext),
  React.useContext(DispatchStateContext),
];

ReactDOM.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
