import React from 'react';
import { Routes, Route } from "react-router-dom";

// STATE
import { useGlobalState } from './index';

// MODULES
import AboutDrop from './AboutDrop';
import Explore from './Explore';
import Header from './shared/Header';
import Home from './Home';
import JcobHeader from './shared/JcobHeader';
import Sound from './Sound';
import Watch from './Watch';

// STYLES
import './App.scss';

function App() {

  const [state, dispatch] = useGlobalState();

  return (
    <>
      <Header />
      <div className="app-container">
        <div id="jcob-container-elem" className="jcob-container">
          <JcobHeader />
          <div className="jcob-content-container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/:watchName" element={<Watch />} />
              <Route path="/about-the-drop" element={<AboutDrop />} />
            </Routes>
          </div>
          {!state.isFirstNavigation ? <Sound /> : null}
        </div>
      </div>
      {/* <div className="version-number">v1.4.1</div> */}
    </>
  );
};

export default App;
