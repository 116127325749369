import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

// THREE
import { createScene } from './scene';
import { loadBackGroundParticles } from './particles';
import { galaxyState, initGalaxyParticles } from './galaxy';
import { addPlanets } from './planets';

export let mainCamera, watchCamera;
export let renderer, clock, raycaster, mouse;
export let textures = [];
export let meshes = [];
export let scenes = [];

export const mainCameraState = {
    state: {
        initialState: {
            position: { x: 0, y: 0, z: 900 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
    state600: {
        initialState: {
            position: { x: 0, y: 0, z: 920 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
    state400: {
        initialState: {
            position: { x: 0, y: 0, z: 950 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
}

export const watchCamaraState = {
    state: {
        initialState: {
            position: { x: 0, y: 0, z: 900 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
    state600: {
        initialState: {
            position: { x: 0, y: 0, z: 900 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
    state400: {
        initialState: {
            position: { x: 0, y: 0, z: 900 },
            rotation: { x: 0, y: 0, z: 0 }
        }
    },
}

export function getMainCameraState() {
    if (window.innerWidth < 400) {
        return mainCameraState.state400;
    } else if (window.innerWidth <= 600) {
        return mainCameraState.state600;
    } else if (window.innerWidth > 600) {
        return mainCameraState.state;
    }
}

export function getWatchCameraState() {
    if (window.innerWidth < 400) {
        return watchCamaraState.state400;
    } else if (window.innerWidth <= 900) {
        return watchCamaraState.state600;
    } else if (window.innerWidth > 900) {
        return watchCamaraState.state;
    }
}

export function init() {

    const mainCameraState = getMainCameraState();
    mainCamera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 2000);
    mainCamera.position.z = mainCameraState.initialState.position.z;

    const watchCameraState = getWatchCameraState();
    watchCamera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 2000);
    watchCamera.position.z = watchCameraState.initialState.position.z;

    clock = new THREE.Clock(true);

    initBackGroundParticles();

    initGalaxyParticles(scenes[0]);
    addPlanets(scenes[0], 0);

    // initPlanetsScene();
    initWatchScene();

    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2(0, 0);

    initRenderer();

    document.body.appendChild(renderer.domElement);
    document.body.style.touchAction = 'none';

    // registerSceneEvents(scenes[0]);
    window.addEventListener('resize', onWindowResize);
    browserBackForwardRegisterEvent();

}

export function setCameraDepth(camera, z1, z2, z3) {
    if (window.innerWidth < 400) {
        camera.position.z = z1;
    } else if (window.innerWidth <= 600) {
        camera.position.z = z2;
    } else if (window.innerWidth > 600) {
        camera.position.z = z3;
    }
}

export function animate() {

    requestAnimationFrame(animate);

    render();

    TWEEN.update();

}

function initBackGroundParticles() {
    const scene = createScene(galaxyState.position, galaxyState.rotation);
    scene.name = 'main-scene';
    createLight(scenes[0], 0, 0, 500);
    loadBackGroundParticles(scenes[0]);
}

function createLight(scene, x, y, z) {

    const ambient = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambient);

    const spotLight = new THREE.SpotLight(0xffffff);
    spotLight.castShadow = true;

    // spotLight.angle = Math.PI / 4;
    // spotLight.penumbra = 0.1;
    // spotLight.decay = 2;
    // spotLight.distance = 200;

    // spotLight.castShadow = true;
    // spotLight.shadow.mapSize.width = 512;
    // spotLight.shadow.mapSize.height = 512;
    // spotLight.shadow.camera.near = 10;
    // spotLight.shadow.camera.far = 200;
    // spotLight.shadow.focus = 1;

    spotLight.position.set(x, y, z);
    scene.add(spotLight);

}

function initWatchScene() {
    const scene = createScene({ x: 0, y: 0, z: 0 }, { x: 0, y: 0, z: 0 });
    scene.name = 'planet-item-scene';
    scene.visible = false;
}

function initRenderer() {
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.autoClear = false;
    renderer.shadowMap.enabled = true;
    renderer.sortObjects = true;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
}

function render() {

    renderer.render(scenes[0], mainCamera);
    renderer.render(scenes[1], watchCamera);

}

function onWindowResize() {

    mainCamera.aspect = window.innerWidth / window.innerHeight;
    mainCamera.updateProjectionMatrix();

    watchCamera.aspect = window.innerWidth / window.innerHeight;
    watchCamera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

}

function browserBackForwardRegisterEvent() {
    window.addEventListener('popstate', event => {
        window.location.href = window.location.pathname;
    }, false);
};