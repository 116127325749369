import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

// STATE
import { useGlobalState } from '../index';

// THREE JS
import { scenes } from '../Three/index';
import { galaxyState } from '../Three/galaxy';
import { animatePlanets } from '../Three/planets';
import { animateScene } from '../Three/scene';

// MODULES
import ExploreAllButton from '../shared/ExploreAllButton';
import Loader from '../Loader';

// STYLES
import './styles.scss';

// CONSTANTS
export const EXPLORE_ALL = 'Explore All';

function AboutDrop() {

    const [state, dispatch] = useGlobalState();

    const navigate = useNavigate();

    const initThree = () => {
        if (state.isNavigation && !state.isFromHome) {
            scenes[0].position.set(-50, galaxyState.position.y, galaxyState.position.z);
            scenes[0].rotation.set(galaxyState.rotation.x, 0.7, galaxyState.rotation.z);
        }
        if (window.innerWidth >= 960) {
            let planetGroup = scenes[0].children.find((children: any) => children.name === 'planet-group');
            planetGroup.children.forEach((children: any, i: number) => {
                children.material.opacity = 1;
            });
        }
    };

    const goExplore = () => {

        dispatch({ isAboutDrop: false, isNavigation: false });

        if (window.innerWidth < 960) {
            animateScene(scenes[0], { position: { x: 0, y: 0, z: 777 }, rotation: { x: -0.7, y: 0, z: scenes[0].rotation.z } }, 1500);
        } else {
            animateScene(scenes[0], { position: { x: 0, y: 0, z: 807 }, rotation: { x: -0.7, y: 0, z: scenes[0].rotation.z } }, 1500);
        }
        animatePlanets(scenes[0], { opacity: 1 }, 1500);

        setTimeout(() => {
            navigate('/explore');
        }, 1500);

    };

    const setAboutDropState = () => {

        dispatch({ isAboutDrop: true, isFirstNavigation: false, isFromHome: false });
    };

    useEffect(() => {
        initThree();
        setAboutDropState();
        setTimeout(() => {
            dispatch({ isNavigation: true });
        }, 1500);
    }, []);

    if (state.loadingState) return (<Loader />);

    return (
        <div id="about-drop" className={`about-drop-container ${state.isAboutDrop === true ? 'fade-in' : ''}`}>
            <ExploreAllButton arrowDirection="left" buttonText={EXPLORE_ALL} action={goExplore} />
            <div className="about-drop-content">
                <h1>About the drop</h1>
                <p>The Astronomia Metaverso collection features 8 unique watches turned into NFTs. Drawn from the metaverse, each NFT returns with a special set of powers. Each watch is represented by a digital NFT artwork inspired by a metaversal version of planets from our solar system.</p>
                <p>Each NFT functions as an exclusive membership pass, unlocking physical, digital, and experiential privileges. The watches from the five closest planets (Mercury, Venus, Earth, Mars, Jupiter) are paired with the physical version of the watch, while the 3 most distant planets (Saturn, Uranus, Neptune) have splintered into multiple digital-only variants.</p>
                <p>Details of the auction will follow.</p>
            </div>
        </div>
    );
}

export default AboutDrop;