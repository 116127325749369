import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';

// THREE
import { planetState } from '../../Three/planets';
import { scenes } from '../../Three';

// TWEEN
import * as TWEEN from '@tweenjs/tween.js';

// SWIPER
import { Swiper, SwiperSlide } from 'swiper/react';

// STYLES
import './styles.scss';
import 'swiper/css';

export const planetList = [
    { id: 0, name: 'Mercury' },
    { id: 1, name: 'Venus' },
    { id: 2, name: 'Earth' },
    { id: 3, name: 'Mars' },
    { id: 4, name: 'Jupiter' },
    { id: 5, name: 'Saturn' },
    { id: 6, name: 'Uranus' },
    { id: 7, name: 'Neptune' }
];

function Navigator({ setFadeInState }: any) {

    const { watchName } = useParams<{ watchName: string }>();
    const navigate = useNavigate();
    const location = useLocation();

    const [swiper, setSwiper] = useState<any>();
    const [slidesPerView, setSlidesPerView] = useState<number>(8);
    const [centeredSlides, setCenteredSlides] = useState<boolean>(false);

    const navigateTo = (planet: any) => {

        if (`/${planet.name}` !== location.pathname) {

            setFadeInState(false);

            new TWEEN.Tween(scenes[1]).to({ position: planetState.unactivePosition }, 1000).easing(TWEEN.Easing.Cubic.Out).start();
            swiper.slideTo(planet.id);

            setTimeout(() => {
                navigate(`/${planet.name}`);
            }, 1000);
        }
    }

    const registerEvents = () => {

        window.addEventListener('resize', () => {
            setSliderConfig();
        }, false);

        window.addEventListener('popstate', (event: any) => {
            const watchPathName = window.location.pathname.substring(1, window.location.pathname.length);
            const foundPlanetId = planetList.find(planet => planet.name === watchPathName)?.id;
            if (foundPlanetId) swiper.slideTo(foundPlanetId);
        }, false);
    }

    const setSliderConfig = () => {
        if (window.innerWidth < 721) {
            setCenteredSlides(true);
            setSlidesPerView(3);
        } else if (window.innerWidth < 1201) {
            setCenteredSlides(true);
            setSlidesPerView(5);
        } else {
            setCenteredSlides(false);
            setSlidesPerView(8);
        }
    }

    const setActiveSlide = () => {
        setTimeout(() => {
            swiper && swiper.slideTo(planetList.find(planet => planet.name === watchName)?.id);
        }, 0);
    }

    useEffect(() => {
        swiper && registerEvents();
        swiper && setSliderConfig();
        swiper && setActiveSlide();
    }, [swiper]);

    return (
        <Swiper
            className="swiper-container"
            spaceBetween={0}
            centeredSlides={centeredSlides}
            slideToClickedSlide={true}
            slidesPerView={slidesPerView}
            onSwiper={(swiper) => setSwiper(swiper)} >
            {planetList.map((planet: any, i: number) =>
                <SwiperSlide className={`planet ${watchName === planet.name ? 'active' : ''}`} onClick={() => navigateTo(planet)} key={i}>{planet.name}</SwiperSlide>
            )}
        </Swiper>
    );
}

export default Navigator;
